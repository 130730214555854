import React, { useState } from "react";
import MobileNavIcon from "@/assets/svg/mobile_nav.svg";
import styles from "./index.module.scss";
import classNames from "classnames";
import dynamic from "next/dynamic";

const MobileNavType1 = dynamic(() => import("@/components/MobileNav/type1"), {
  ssr: false,
});

// const Login = dynamic(() => import("@/components/Login"), {
//   ssr: false,
//   loading: () => (
//     <div style={{ minWidth: 170, textAlign: "center", color: "#fff" }}>
//       Loading...
//     </div>
//   ),
// });

export default function HeaderMobileNav({ logo, menu, isMobile }: any) {
  const [isShow, setIsShow] = useState(false);
  return (
    <>
      {/*{!isMobile && (*/}
      {/*  <div className={styles.login_section}>*/}
      {/*    <Login />*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className={styles.headerMobileNav}>
        <button
          aria-label="search button"
          className={styles.btn}
          onClick={() => {
            setIsShow(() => !isShow);
          }}
        >
          <i>
            <MobileNavIcon></MobileNavIcon>
          </i>
        </button>

        <div
          className={classNames([
            styles.mobile_nav_markList,
            isShow && styles.isShow,
          ])}
        >
          {isShow && (
            <MobileNavType1
              logo={logo}
              menu={menu}
              style={isShow ? { transform: "translateX(0)" } : undefined}
              handleCancel={() => setIsShow(false)}
            ></MobileNavType1>
          )}
        </div>
      </div>
    </>
  );
}
